.steps-container {
  display: flex;
  min-height: 100vh;
}

.steps-form-container {
  height: 100vh;
}

.steps-step-container {
  min-height: 100vh;
}

.stepper {
  max-width: 400px;
}

.registration-text-area {
  background: #fcfdfe !important;
  height: 146px;
}

.registration-text-area:focus {
  outline: 1px solid #f0f1f7;
}

.registration-text-area::placeholder {
  font-weight: 400;
  color: #4b506d;
  opacity: 0.4;
}

.MuiPaper-root::-webkit-scrollbar {
  display: none;
}

.checkbox-align-start{
  align-items: start!important;
}

.registration-text-large-blue {
  font-size: 30px;
  color: #253292;
  font-weight: 700;
}

.registration-text-medium-gray {
  color: #9fa2b4;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.2px;
  text-align: left;
}

.registration-button-w {
  max-width: 295px;
  width: 100%;
}

.registration-text-16-14 {
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  * {
    overflow-y: auto;
    overflow-x: hidden;
  }
  #root {
    overflow: hidden;
  }

  .steps-container {
    width: 200%;
    height: 100%;
    overflow: scroll;
  }
  .steps-form-container {
    padding: 40px;
  }
  .steps-step-container {
    padding: 40px;
  }
  .stepper {
    width: 100%;
  }
  .registration-text-large-blue {
    font-size: 19px;
  }
  .registration-text-medium-gray {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1px;
    text-align: center;
  }
  .checkbox-adjustment{
    margin-left: 2px!important;
  }


  .sm-w-full {
    width: 100%;
  }

  .registration-text-area {
    height: 75px;
  }

  .registration-button-w {
    width: 100%;
    max-width: 100%;
  }
  .registration-text-16-14 {
    font-size: 14px;
  }
  @media screen and (max-width: 768px) {
    .checkbox-adjustment-payment-form {
      margin-left: -2px !important;
    }
    .checkbox-adjustment-company-info-form {
      margin-left: -6px !important;
    }
  }
}
