.registration-checkbox input.unchecked[type="checkbox"]{
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #DFE0EB;
    border-radius: 5px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    flex-shrink: 0;
}

.registration-checkbox input.checked {
    flex-shrink: 0;
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background-image: url('../../../../public/assets/icons/checked.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-color: #253292;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: all 300ms ease-in-out;
}

.registration-checkbox input.checked:disabled input.unchecked:disabled {
    border-color: #c0c0c0;
    background-color: #c0c0c0;
}

.registration-checkbox input.unchecked[type="checkbox"]:hover{
    border: 2px solid #DFE0EB;
}